import React from 'react';
import { Link } from 'react-router-dom';


const Contact = () => {
  return (
    <div>
      <p>Contact page</p>
      <Link to='/' className='resume-link'>BACK</Link>
    </div>
  )
}

export default Contact;