import testImg1 from '../assets/images/testImg1.png';
import testImg2 from '../assets/images/testImg2.png';
import testImg3 from '../assets/images/testImg3.png';
import testImg4 from '../assets/images/testImg4.png';
import testImg5 from '../assets/images/testImg5.png';
import testImg6 from '../assets/images/testImg6.png';
import testImg7 from '../assets/images/testImg7.png';


export const testimonialImages = [
  testImg1,
  testImg2,
  testImg3,
  testImg4,
  testImg5,
  testImg6,
  testImg7
]