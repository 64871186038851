import React from 'react';
import './About.scss';
import '../Resume/Resume.scss';
import { Link } from 'react-router-dom';
import victorImg from '../../assets/images/victor.jpeg';


const About = () => {
  return (
    <div className='about'>
      <Link to='/' className='resume-link about-back'>GO BACK</Link>
      <section className='about-top'>
        <div className='about-block'>
          <h2>I design & build</h2>
          <h2>digital products</h2>
         
        </div>
        <img src={victorImg} alt='Headshot of Victor Abraham.'/>
      </section>
      <section className='about-bottom'>
      <div className='about-bottom__text'> 
            <p>I'm an experienced software engineer with a specialty in developing elegant UI and strictly-typed React and Angular frontends, backed by thoughtful
              APIs built on GQL and REST. With roots in both startups and enterprise environments, I am able to meld into a variety of teams and 
              love to collaborate with other devs. Please don't hesitate to reach out with any further inquiries.
               </p>
          </div>
        <div className='about-bottom__contact'>
            <p>phone: 910.795.7233</p>
            <p>email:  
              <a href='mailto:vpa456@gmail.com'> vpa456@gmail.com</a>
            </p>
            <p>github: 
              <a href='https://github.com/VPAbraham' target='_blank'> github.com/VPAbraham</a>
            </p>
            <p>linkedIn: 
              <a href='https://www.linkedin.com/in/victorpabraham/' target='_blank'> linkedin.com/in/victorpabraham/</a>
            </p>
            
          </div>
      </section>
    </div>
  )
}

export default About